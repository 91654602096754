import { useCallback, useMemo } from "react";
import { useAnalyticsContext } from "@contexts/Analytics";
import packageJson from "../../../package.json";

import { Logger } from "@utils/logging";
import { parseStep } from "@utils/onboarding";
import store from "@store/index";
import useTemplateData from "@hooks/useTemplateData";
import useRudderAnalytics from "@contexts/RudderAnalytics/useRudderAnalytics";
import { RudderStackEvents, RudderStackStepViwedActions } from "@contexts/RudderAnalytics/stepsAndEvents";

const analyticsCheckpointsEmitted = [];

const useAnalytics = () => {
  const { analytics, sessionId, analyticsTrackingId, isAnalyticsInitializing } = useAnalyticsContext();
  const { setRudderTrackData, setRudderPageData } = useRudderAnalytics();

  const { templateData } = useTemplateData();

  const tenantId = useMemo(() => templateData?.tenantId, [templateData]);
  const transactionTemplateId = useMemo(
    () => templateData?.data?.transactionTemplateId ?? templateData?.data?.workflowId,
    [templateData],
  );

  const { token } = store.variables.general;
  const { person: personStore, company: companyStore, general: generalStore } = store.variables;
  const { index: stepIndex, flowMap } = store.navigation;

  const analyticsSteps = {
    /* Steps */
    STEP_DATA_CONFIRMATION: "STEP_DATA_CONFIRMATION",
    STEP_PFPF_DATA: "STEP_PFPF_DATA",
    STEP_QSA: "STEP_QSA",
    // TODO: remove "STEP_PARTNER" after QSA flow separated steps are implemented
    STEP_PARTNER: "STEP_PARTNER",
    STEP_CAMERA_ACCESS: "STEP_CAMERA_ACCESS",
    STEP_DISPATCH_ADDRESS: "STEP_DISPATCH_ADDRESS",
    STEP_DOCUMENT_TYPE: "STEP_DOCUMENT_TYPE",
    STEP_DOCUMENT_REUSE: "STEP_DOCUMENT_REUSE",
    STEP_DOCUMENT_REUSE_TERMS: "STEP_DOCUMENT_REUSE_TERMS",
    STEP_SEND_DOCUMENT_TYPE: "STEP_SEND_DOCUMENT_TYPE",
    STEP_COMPANY_ADDRESS: "STEP_COMPANY_ADDRESS",
    STEP_COMPANY_DATA: "STEP_COMPANY_DATA",
    STEP_COMPANY_LISTING: "STEP_COMPANY_LISTING",
    STEP_COMPANY_SEARCH: "STEP_COMPANY_SEARCH",
    STEP_COMPANY_LOCATION: "STEP_COMPANY_LOCATION",
    STEP_BUSINESS_ADDRESS: "STEP_BUSINESS_ADDRESS",
    STEP_INCOME: "STEP_INCOME",
    STEP_INSTRUCTIONS: "STEP_INSTRUCTIONS",
    STEP_EMPLOYEE_DATA: "STEP_EMPLOYEE_DATA",
    STEP_PHONE_NUMBER_VALIDATION: "STEP_PHONE_NUMBER_VALIDATION",
    STEP_EMAIL_VALIDATION: "STEP_EMAIL_VALIDATION",
    STEP_UPLOAD_DOCUMENT: "STEP_UPLOAD_DOCUMENT",
    STEP_WELCOME: "STEP_WELCOME",
    STEP_NEW_ACCESS: "STEP_NEW_ACCESS",
    STEP_FLOW_CHOICE: "STEP_FLOW_CHOICE",
    STEP_CUSTOM: "STEP_CUSTOM",
    STEP_QSA_CONTACT: "STEP_QSA_CONTACT",
    STEP_QSA_CONTACT_INFO: "STEP_QSA_CONTACT_INFO",
    STEP_QSA_NUMBER: "STEP_QSA_NUMBER",
    STEP_PFL_PREVIEW: "STEP_PFL_PREVIEW",
    STEP_LIVENESS_CLEAR_SALE_PREVIEW: "STEP_LIVENESS_CLEAR_SALE_PREVIEW",
    STEP_FA_PREVIEW: "STEP_FA_PREVIEW",
    STEP_CUSTOM_FORM: "STEP_CUSTOM_FORM",
    STEP_DONE: "STEP_DONE",
    STEP_USING_TERMS: "STEP_USING_TERMS",
    STEP_DOCUMENT_ISSUED_COUNTRY: "STEP_DOCUMENT_ISSUED_COUNTRY",
    // SDK steps
    STEP_DD: "STEP_DD",
    STEP_PFL: "STEP_PFL",
    STEP_LIVENESS_CLEAR_SALE: "STEP_LIVENESS_CLEAR_SALE",
    STEP_FA: "STEP_FA",
    STEP_FACE_AUTH_IPROOV: "STEP_FACE_AUTH_IPROOV",
    STEP_FACE_AUTH_FACETEC_3D: "STEP_FACE_AUTH_FACETEC_3D",
    STEP_FACE_AUTH_FACETEC_2D: "STEP_FACE_AUTH_FACETEC_2D",
    STEP_LIVENESS_IPROOV: "STEP_LIVENESS_IPROOV",
    STEP_LIVENESS_FACETEC_3D: "STEP_LIVENESS_FACETEC_3D",
    STEP_LIVENESS_FACETEC_2D: "STEP_LIVENESS_FACETEC_2D",
    // Preview steps
    STEP_DD_PREVIEW: "STEP_DD_PREVIEW",
    STEP_FACE_AUTH_IPROOV_PREVIEW: "STEP_FACE_AUTH_IPROOV",
    STEP_LIVENESS_IPROOV_PREVIEW: "STEP_LIVENESS_IPROOV_PREVIEW",
    STEP_LIVENESS_FACETEC_3D_PREVIEW: "STEP_LIVENESS_FACETEC_3D_PREVIEW",
    STEP_LIVENESS_FACETEC_2D_PREVIEW: "STEP_LIVENESS_FACETEC_2D_PREVIEW",
  };

  const analyticsEvents = {
    NEW_ACCESS: "NEW_ACCESS",
    DOCUMENT_TYPE_SELECTED: "DOCUMENT_TYPE_SELECTED",
    ACCEPT_TERM: "ACCEPT_TERM",
    UNACCEPT_TERM: "UNACCEPT_TERM",
    FINAL_POST: "FINAL_POST",
    SHOULD_SEND_FINAL_PF_POST: "SHOULD_SEND_FINAL_PF_POST",
    FINISHED: "FINISHED",
    FINISHED_ERROR: "FINISHED_ERROR",
    DD_START: "DD_START",
    DD_INITIALIZE_DURATION: "DD_INITIALIZE_DURATION",
    DD_CLOSE: "DD_CLOSE",
    DD_ERROR: "DD_ERROR",
    DD_CAPTURE_INVALID: "DD_CAPTURE_INVALID",
    DD_CAPTURE: "DD_CAPTURE",
    PFL_START: "PFL_START",
    PFL_CLOSE: "PFL_CLOSE",
    PFL_ERROR: "PFL_ERROR",
    DOCUMENT_REUSE_RESULT: "DOCUMENT_REUSE_RESULT",
    DOCUMENT_REUSE_ERROR: "DOCUMENT_REUSE_ERROR",
    SDK_TOKEN_ERROR: "SDK_TOKEN_ERROR",
    LIVENESS_IPROOV_PASSED: "LIVENESS_IPROOV_PASSED",
    LIVENESS_IPROOV_PERMISSION: "LIVENESS_IPROOV_PERMISSION",
    LIVENESS_IPROOV_PERMISSION_DENIED: "LIVENESS_IPROOV_PERMISSION_DENIED",
    LIVENESS_IPROOV_ERROR: "LIVENESS_IPROOV_ERROR",
    LIVENESS_IPROOV_FAILED: "LIVENESS_IPROOV_FAILED",
    LIVENESS_IPROOV_CANCELED: "LIVENESS_IPROOV_CANCELED",
    LIVENESS_IPROOV_MULTIPLE_CAMERAS: "LIVENESS_IPROOV_MULTIPLE_CAMERAS",
    LIVENESS_IPROOV_NO_CAMERA: "LIVENESS_IPROOV_NO_CAMERA",
    LIVENESS_IPROOV_UNSUPPORTED: "LIVENESS_IPROOV_UNSUPPORTED",
    LIVENESS_IPROOV_START: "LIVENESS_IPROOV_START",
    LIVENESS_IPROOV_CLOSE: "LIVENESS_IPROOV_CLOSE",
    LIVENESS_IPROOV_INFO: "LIVENESS_IPROOV_INFO",
    LIVENESS_FACETEC_3D_START: "LIVENESS_FACETEC_3D_START",
    LIVENESS_FACETEC_3D_CLOSE: "LIVENESS_FACETEC_3D_CLOSE",
    LIVENESS_FACETEC_3D_ERROR: "LIVENESS_FACETEC_3D_ERROR",
    LIVENESS_FACETEC_3D_INFO: "LIVENESS_FACETEC_3D_INFO",
    LIVENESS_FACETEC_2D_START: "LIVENESS_FACETEC_2D_START",
    LIVENESS_FACETEC_2D_CLOSE: "LIVENESS_FACETEC_2D_CLOSE",
    LIVENESS_FACETEC_2D_ERROR: "LIVENESS_FACETEC_2D_ERROR",
    LIVENESS_FACETEC_2D_INITIALIZE_ERROR: "LIVENESS_FACETEC_2D_INITIALIZE_ERROR",
    LIVENESS_FACETEC_2D_CAPTURE_ERROR: "LIVENESS_FACETEC_2D_CAPTURE_ERROR",
    LIVENESS_FACETEC_2D_INFO: "LIVENESS_FACETEC_2D_INFO",
    LIVENESS_CLEARSALE_START: "LIVENESS_CLEARSALE_START",
    LIVENESS_CLEARSALE_ERROR: "LIVENESS_CLEARSALE_ERROR",
    LIVENESS_CLEARSALE_INFO: "LIVENESS_CLEARSALE_INFO",
    LIVENESS_CLEARSALE_UPLOAD: "LIVENESS_CLEARSALE_UPLOAD",
    LIVENESS_CLEARSALE_UPLOAD_ERROR: "LIVENESS_CLEARSALE_UPLOAD_ERROR",
    FA_START: "FA_START",
    FA_CLOSE: "FA_CLOSE",
    FA_WEB_HOOK_ERROR: "FA_WEB_HOOK_ERROR",
    FACE_AUTH_IPROOV_PASSED: "FACE_AUTH_IPROOV_PASSED",
    FACE_AUTH_IPROOV_PERMISSION: "FACE_AUTH_IPROOV_PERMISSION",
    FACE_AUTH_IPROOV_PERMISSION_DENIED: "FACE_AUTH_IPROOV_PERMISSION_DENIED",
    FACE_AUTH_IPROOV_ERROR: "FACE_AUTH_IPROOV_ERROR",
    FACE_AUTH_IPROOV_FAILED: "FACE_AUTH_IPROOV_FAILED",
    FACE_AUTH_IPROOV_CANCELED: "FACE_AUTH_IPROOV_CANCELED",
    FACE_AUTH_IPROOV_MULTIPLE_CAMERAS: "FACE_AUTH_IPROOV_MULTIPLE_CAMERAS",
    FACE_AUTH_IPROOV_NO_CAMERA: "FACE_AUTH_IPROOV_NO_CAMERA",
    FACE_AUTH_IPROOV_UNSUPPORTED: "FACE_AUTH_IPROOV_UNSUPPORTED",
    FACE_AUTH_IPROOV_START: "FACE_AUTH_IPROOV_START",
    FACE_AUTH_IPROOV_CLOSE: "FACE_AUTH_IPROOV_CLOSE",
    FACE_AUTH_IPROOV_WEB_HOOK_ERROR: "FACE_AUTH_IPROOV_WEB_HOOK_ERROR",
    FACE_AUTH_FACETEC_3D_START: "FACE_AUTH_FACETEC_3D_START",
    FACE_AUTH_FACETEC_3D_CLOSE: "FACE_AUTH_FACETEC_3D_CLOSE",
    FACE_AUTH_FACETEC_3D_ERROR: "FACE_AUTH_FACETEC_3D_ERROR",
    FACE_AUTH_FACETEC_3D_WEB_HOOK_ERROR: "FACE_AUTH_FACETEC_3D_WEB_HOOK_ERROR",
    FACE_AUTH_FACETEC_2D_START: "FACE_AUTH_FACETEC_2D_START",
    FACE_AUTH_FACETEC_2D_CLOSE: "FACE_AUTH_FACETEC_2D_CLOSE",
    FACE_AUTH_FACETEC_2D_ERROR: "FACE_AUTH_FACETEC_2D_ERROR",
    FACE_AUTH_FACETEC_2D_INITIALIZE_ERROR: "FACE_AUTH_FACETEC_2D_INITIALIZE_ERROR",
    FACE_AUTH_FACETEC_2D_CAPTURE_ERROR: "FACE_AUTH_FACETEC_2D_CAPTURE_ERROR",
    FACE_AUTH_FACETEC_2D_INFO: "FACE_AUTH_FACETEC_2D_INFO",
    FACE_AUTH_FACETEC_2D_WEB_HOOK_ERROR: "FACE_AUTH_FACETEC_2D_WEB_HOOK_ERROR",
    CAMERA_ACCESS_DENIED: "CAMERA_ACCESS_DENIED",
    CAMERA_ACCESS_UNSUPPORTED: "CAMERA_ACCESS_UNSUPPORTED",
    CAMERA_ACCESS_ERROR: "CAMERA_ACCESS_ERROR",
    GEO_LOCATION_ERROR: "GEO_LOCATION_ERROR",
  };

  const analyticsActions = {
    REFRESH_PAGE: "REFRESH_PAGE",
    SELECT_COUNTRY: "SELECT_COUNTRY",
    SELECT_DOCUMENT_SIDE: "SELECT_DOCUMENT_SIDE",
    DOCUMENT_TYPE_SELECTED: "DOCUMENT_TYPE_SELECTED",
    TERM_ACCEPTED: "TERM_ACCEPTED",
    SELECT_DOCUMENT_TYPE: "SELECT_DOCUMENT_TYPE",
    CHOOSE_FILE_TYPE: "CHOOSE_FILE_TYPE",
    GET_CAMERA_PERMISSIONS: "GET_CAMERA_PERMISSIONS",
    DD_CAPTURE_START: "DD_CAPTURE_START",
    DD_CAPTURE_FAILED: "DD_CAPTURE_FAILED",
    DD_PREVIEW_RETRY: "DD_PREVIEW_RETRY",
    PFL_CAPTURE_START: "PFL_CAPTURE_START",
    PFL_CAPTURE_FAILED: "PFL_CAPTURE_FAILED",
    PFL_PREVIEW_RETRY: "PFL_PREVIEW_RETRY",
    LIVENESS_FACETEC_2D_PREVIEW_RETRY: "LIVENESS_FACETEC_2D_PREVIEW_RETRY",
    LIVENESS_CLEARSALE_CAPTURE_START: "LIVENESS_CLEARSALE_CAPTURE_START",
    LIVENESS_CLEARSALE_CAPTURE_FAILED: "LIVENESS_CLEARSALE_CAPTURE_FAILED",
    LIVENESS_CLEARSALE_PREVIEW_RETRY: "LIVENESS_CLEARSALE_PREVIEW_RETRY",
    FA_CAPTURE_START: "FA_CAPTURE_START",
    FA_CAPTURE_FAILED: "FA_CAPTURE_FAILED",
    FIELD_CHANGE: "FIELD_CHANGE",
    ONBOARDING_REDIRECT: "ONBOARDING_REDIRECT",
    DOCUMENT_UPLOADED: "DOCUMENT_UPLOADED",
    FILLED_INCOME_INPUT: "FILLED_INCOME_INPUT",
    SELECT_FLOW: "SELECT_FLOW",
    CNPJ_FIELD_CHANGE: "CNPJ_FIELD_CHANGE",
    FILLED_QSA_NUMBER: "FILLED_QSA_NUMBER",
    REQUIRED_FIELD_CHANGED: "REQUIRED_FIELD_CHANGED",
    OPTIONAL_FIELD_CHANGED: "OPTIONAL_FIELD_CHANGED",
    SELECT_QSA: "SELECT_QSA",
    FILLED_PHONE_CODE: "FILLED_PHONE_CODE",
    RESEND_PHONE_CODE: "RESEND_PHONE_CODE",
    FILLED_EMAIL_CODE: "FILLED_EMAIL_CODE",
    RESEND_EMAIL_CODE: "RESEND_EMAIL_CODE",
    FILLED_ALL_QSA_CONTACTS: "FILLED_ALL_QSA_CONTACTS",
    DOCUMENT_FOUND: "DOCUMENT_FOUND",
    DOCUMENT_NOT_FOUND: "DOCUMENT_NOT_FOUND",
    DOCUMENT_REUSE_SELECTED_FLOW: "DOCUMENT_REUSE_SELECTED_FLOW",
    ONBOARDING_STARTED: "ONBOARDING_STARTED",
    STEP_STARTED: "STEP_STARTED",
    STEP_COMPLETED: "STEP_COMPLETED",
    SDK_CAMERA_DENIED: "SDK_CAMERA_DENIED",
    LIVENESS_CAPTURE: "LIVENESS_CAPTURE",
    SDK_CAPTURE_DENIED: "SDK_CAPTURE_DENIED",
    SDK_CAPTURE_FAILED: "SDK_CAPTURE_FAILED",
    SDK_CAPTURE_INVALID: "SDK_CAPTURE_INVALID",
    SDK_CAPTURE_RESULT: "SDK_CAPTURE_RESULT",
    LIVENESS_PREVIEW_RETRY: "LIVENESS_PREVIEW_RETRY",
    SDK_FRONT_CAPTURE_STARTED: "SDK_FRONT_CAPTURE_STARTED",
    SDK_BACK_CAPTURE_STARTED: "SDK_BACK_CAPTURE_STARTED",
    SDK_CAPTURE_ERROR: "SDK_CAPTURE_ERROR",
    DD_CAPTURE_FINISH: "DD_CAPTURE_FINISH",
    FAILED_PHONE_CODE: "FAILED_PHONE_CODE",
    FAILED_EMAIL_CODE: "FAILED_EMAIL_CODE",
  };

  const logAnalyticsEventInfo = async (name, data = {}) => {
    try {
      const analyticsInfo = await analytics?.getAnalyticsInfo?.();

      const event = {
        name,
        token,
        tenantId,
        cpf: personStore.personCpf || null,
        date: new Date().toISOString(),
        trackingId: analyticsInfo?.trackingId,
        trackingIdServer: analyticsTrackingId,
        device: analyticsInfo?.device,
        transactionid: generalStore?.transactionid,
        data,
        sessionId,
        version: packageJson.version,
      };

      if (analytics) {
        analytics.log("event", 2, event);
        Logger.info("Analytics - logAnalyticsEventInfo", event);
      }
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't log Analytics SDK event info.",
      });
    }
  };

  const logAnalyticsStepInfo = useCallback(
    async (logName, otherInfo = {}) => {
      try {
        const { currentStep, lastIndex, index } = store.navigation;

        const stepName = currentStep?.name;
        const stepOrder = stepIndex + 1;

        if (!analyticsCheckpointsEmitted.includes(stepName) && analytics) {
          analyticsCheckpointsEmitted.push(stepName);

          const analyticsInfo = await analytics?.getAnalyticsInfo?.();
          const checkpointStepName = `${logName}-${parseStep(stepName)[1] + 1}`;

          const flow = Object.keys(flowMap)
            .filter((key) => !!flowMap[key])
            .map((key) => flowMap[key]);

          const stepNew = {
            ...otherInfo,
            tenantId: tenantId,
            stepName: logName === analyticsSteps.STEP_NEW_ACCESS ? logName : checkpointStepName,
            stepOrder,
            cpf: personStore.personCpf || null,
            cnpj: companyStore.companyCnpj || null,
            flow,
            transactionTemplateId,
            token,
            trackingId: analyticsInfo?.trackingId,
            trackingIdServer: analyticsTrackingId,
            device: analyticsInfo?.device,
            transactionid: generalStore?.transactionid,
            date: new Date().toISOString(),
            sessionId,
          };

          if (analytics) {
            await analytics.indicator("checkpoint", 2, stepNew);
            Logger.info("Analytics - logAnalyticsStepInfo", stepNew);
          }
        }

        // RudderStack analytics
        const defineAction = () => {
          if (index < lastIndex) return RudderStackStepViwedActions.PREVIOUS;
          if (index > lastIndex) return RudderStackStepViwedActions.NEXT;

          return "";
        };

        if (logName === analyticsSteps.STEP_NEW_ACCESS) {
          setRudderTrackData(RudderStackEvents.ONBOARDING_STARTED, {
            tenantId: templateData?.tenantId || "",
            templateId: transactionTemplateId,
          });
          setRudderPageData(RudderStackEvents.PAGE_EVENT_ONBOARDING);
        } else {
          setRudderTrackData(RudderStackEvents.STEP_VIEWED, {
            tenantId: templateData?.tenantId || "",
            action: defineAction(),
            stepName,
            stepOrder,
          });
        }
      } catch (error) {
        Logger.error(error, {
          message: "Couldn't log Analytics SDK event info.",
        });
      }
    },
    [analytics, analyticsCheckpointsEmitted, analyticsTrackingId, tenantId, sessionId, transactionTemplateId, token],
  );

  const logAnalyticsActionInfo = useCallback(
    async (actionName, otherInfo = {}, stepName) => {
      try {
        const { currentStep } = store.navigation;

        const [name, index] = parseStep(stepName ?? currentStep?.name);

        const action = {
          tenantId,
          sessionId,
          transactionTemplateId,
          actionName,
          stepName: `STEP_${name}-${index + 1}`,
          date: new Date().toISOString(),
          transactionid: generalStore?.transactionid,
          otherInfo,
        };

        if (analytics) {
          await analytics.indicator("actions", 1, action);
          Logger.info("Analytics - logAnalyticsActionInfo", action);
        }
      } catch (error) {
        Logger.error(error, {
          message: "Couldn't log Analytics SDK action info.",
        });
      }
    },
    [analytics, tenantId, sessionId, transactionTemplateId],
  );

  return {
    analyticsSteps,
    analyticsEvents,
    analyticsActions,
    logAnalyticsEventInfo,
    logAnalyticsStepInfo,
    logAnalyticsActionInfo,
    isAnalyticsInitializing,
  };
};

export default useAnalytics;
