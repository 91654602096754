import { Step } from "@steps/index";
import { applyVariable } from "@utils/formatting";
import { Logger } from "@utils/logging";
import { parseStep } from "@utils/onboarding";
import cloneDeepWith from "lodash.clonedeepwith";
import { makeAutoObservable, computed, reaction, toJS } from "mobx";
import { RootStore } from "..";
import { QSABehavior } from "types";
import { throttle } from "lodash";

export interface Steps {
  name: Step | string;
  props: {
    flow?: string[];
    name: string;
    subheading?: string;
    heading?: string;
    fields?: object[];
    terms?: object[];
    button?: string;
    retryText?: string;
    manual?: boolean;
    qsaIndex?: number;
    illustration?: string;
    contactHeading?: string;
    contactSubheading?: string;
    contactInfoHeading?: string;
    contactInfoSubheading?: string;
    contactInfoIllustration?: string;
    fromEmployee?: boolean;
    behavior?: QSABehavior;
    isAttorney?: boolean;
  };
}

type RootSteps = Steps["props"][];
type StoreSteps = Steps["props"];

export default class NavigationStore {
  private rootSteps: RootSteps | null = null;

  index = 0;
  lastIndex = 0;
  liveStepCountUse = 1;
  steps: Steps[] = [];
  flowMap: Record<string, string | null> = {};

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {
      percentage: computed,
      currentStep: computed,
      selectedFlow: computed,
    });

    reaction(
      () => this.index,
      () => {
        if (this.currentStep) this.setStep(this.currentStep.props, this.index);

        Logger.console("NavigationStore", {
          index: this.index,
          currentStep: toJS(this.currentStep),
          steps: toJS(this.steps),
          flowMap: toJS(this.flowMap),
          selectedFlow: toJS(this.selectedFlow),
        });
      },
    );
  }

  getGoForward(throttleDuration: number) {
    const goForwardFn = () => {
      const { person } = this.rootStore.variables;
      this.lastIndex = this.index;

      const nextStep = this.steps[this.index + 1];
      const afterNextStep = this.steps[this.index + 2];

      const shouldSkipStep = (step: Steps | undefined) =>
        step &&
        ((step.name.includes("PHONE_NUMBER_VALIDATION") && person.personValitatedPhones[person.personPhoneNumber]) ||
          (step.name.includes("EMAIL_VALIDATION") && person.personValidatedEmails[person.personEmail]));

      if (shouldSkipStep(nextStep)) {
        this.index += shouldSkipStep(afterNextStep) ? 3 : 2;
      } else {
        this.index += 1;
      }
    };

    return throttleDuration > 0 ? throttle(goForwardFn, throttleDuration) : goForwardFn;
  }

  getGoBackward(throttleDuration: number) {
    const goBackwardFn = () => {
      this.lastIndex = this.index;

      const prevStep = this.steps[this.index - 1];
      const beforePrevStep = this.steps[this.index - 2];

      if (
        prevStep &&
        (prevStep.name.includes("PHONE_NUMBER_VALIDATION") || prevStep.name.includes("EMAIL_VALIDATION"))
      ) {
        this.index -=
          beforePrevStep &&
          (beforePrevStep.name.includes("PHONE_NUMBER_VALIDATION") || beforePrevStep.name.includes("EMAIL_VALIDATION"))
            ? 3
            : 2;
      } else {
        this.index -= 1;
      }
    };
    return throttleDuration > 0 ? throttle(goBackwardFn, throttleDuration) : goBackwardFn;
  }

  goForwardWithoutInterval = this.getGoForward(0);

  goBackwardWithoutInterval = this.getGoBackward(0);

  goForward = this.getGoForward(1500);

  goBackward = this.getGoBackward(1500);

  getPreviousStep = () => {
    return this.steps[this.index - 1];
  };

  getStep = (index: number) => {
    return this.steps[index];
  };

  setSteps = (steps: RootSteps) => {
    if (this.rootSteps === null && steps.length > 0) this.rootSteps = steps;

    this.steps = steps.map((step) => ({ name: step.name as Step, props: this.parseStepProps(step) }));
  };

  setStep = (step: StoreSteps, index: number) => {
    const rootStep = this.rootSteps?.find(({ name }) => name === step.name);

    this.steps[index] = {
      name: step.name as Step,
      props: this.parseStepProps({ ...step, ...rootStep }),
    };
  };

  setStepProps = (step: Step, getProps: (currentProps: StoreSteps) => StoreSteps) => {
    const index = this.steps.findIndex((s) => parseStep(s.name)[0] === step);
    const currentStep = this.steps.at(index);

    if (currentStep) {
      this.setStep(getProps(currentStep.props), index);
    }
  };

  liveStepCount = () => {
    this.liveStepCountUse += 1;
  };

  removeStep = (step: Step) => {
    // filter stpes
    this.steps = this.steps.filter((s) => parseStep(s.name)[0] !== step);
  };

  addStep = (step: Steps, index: number) => {
    this.steps.splice(index, 0, step);
  };

  haveStep = (step: Step) => {
    return this.steps.some((s) => parseStep(s.name)[0] === step);
  };

  get percentage() {
    return (100 / this.steps.length) * (this.index + 1);
  }

  get currentStep() {
    return this?.steps?.at(this?.index);
  }

  get nextStep() {
    return this?.steps?.at(this?.index + 1);
  }

  get selectedFlow() {
    const lastAddedIndex = Object.keys(this.flowMap)
      .reverse()
      .find((key) => this.flowMap[key] !== null);

    if (!lastAddedIndex) return null;

    return this.flowMap[lastAddedIndex];
  }

  get allSelectedFlows() {
    return Object.values(this.flowMap) || [];
  }

  setSelectedFlow = (selectedFlow: NavigationStore["selectedFlow"]) => {
    this.flowMap[this.index] = selectedFlow;

    if (this.rootSteps && selectedFlow) {
      const nextSteps = toJS(this.rootSteps)
        .filter(({ flow }) => flow?.includes(selectedFlow))
        .map((step) => ({ name: step.name as Step, props: this.parseStepProps(step) }));

      this.steps = this.steps.slice(0, this.index + 1).concat(nextSteps);
    }
  };

  parseStepProps = (step: StoreSteps) => {
    const parsedStep = cloneDeepWith(step, (v) => {
      if (typeof v === "string") {
        return applyVariable(v, true, this.rootStore.variables.flatten());
      }
    });
    if (step?.terms) {
      // terms are parsed separately
      parsedStep.terms = step.terms;
    }

    return parsedStep;
  };
}
